<template>
	<div class="modal-content">
		<form @submit.prevent="handleSubmit">
			<div class="modal-header">
				<h5 class="modal-title my-0">Add agenda item</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<ul class="nav nav-tabs mb-4">
					<li class="nav-item">
						<span class="nav-link active">Typed manually</span>
					</li>
					<li class="nav-item">
						<span class="nav-link disabled"
							>Agenda items queue <span class="badge bg-neutral-100 text-dark">0</span></span
						>
					</li>
				</ul>
				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-title"
						>Agenda item <small class="text-danger-400">*</small></label
					>
					<input
						type="text"
						class="form-control"
						id="agenda-item-title"
						v-model="item.title"
						required
						minlength="3"
						placeholder="What should be discussed?"
					/>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-text">Text / Description</label>

					<textarea
						class="form-control"
						id="agenda-item-text"
						v-model="item.text"
						placeholder="More information about this agenda item (optional)"
					></textarea>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-internal-note">Internal note</label>
					<textarea
						class="form-control"
						id="agenda-item-internal-note"
						v-model="item.internal_note"
						placeholder="Comment that only logged in clerks see, to share notes about an agenda item (optional)"
					></textarea>
				</div>

				<div class="form-group mb-3">
					<label class="form-label">Speaker / Presenter</label>
					<people-search
						@person="setSpeaker"
						:limit="5"
						:required="false"
						:placeholder="'Name/email/phone of a person from jurisdiction'"
					></people-search>
				</div>

				<div class="form-row">
					<label class="form-label" for="agenda-item-file">Attachments (documents or other files)</label>

					<div class="row row-cols-3 gap-0">
						<div v-for="(file, index) in item.files" :key="`file-${index}`" class="col mb-3">
							<file-preview :file="file" @remove="removeFile" />
						</div>
						<div class="col mb-3">
							<label
								class="d-block bg-light border border-dashed rounded-1 p-3 text-center cursor-pointer"
							>
								<font-awesome-icon :icon="['fas', 'file']" class="text-primary mb-2" />

								<p class="mb-0">
									Upload file
								</p>

								<input
									type="file"
									class="form-control d-none"
									id="agenda-item-file"
									@change="addFile"
									multiple
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-primary">Save</button>
			</div>
		</form>
	</div>
</template>

<script>
import PeopleSearch from '@/components/PeopleSearch.vue'
import FilePreview from '@/components/FilePreview.vue'

export default {
	name: 'MeetingAgenda',
	components: { PeopleSearch, FilePreview },
	data() {
		return { item: this.getEmptyItem() }
	},
	methods: {
		getEmptyItem() {
			return {
				title: '',
				text: '',
				internal_note: '',
				speaker_id: null,
				files: [],
			}
		},
		handleSubmit() {
			this.$emit('submit', {
				title: this.item.title,
				text: this.item.text,
				internal_note: this.item.internal_note,
				speaker_id: this.item.speaker_id,
				files: this.item.files,
			})
			this.item = this.getEmptyItem()
		},
		setSpeaker(speaker) {
			this.item.speaker_id = speaker.id
		},
		addFile(event) {
			this.item.files.push(...event.target.files)
		},
		removeFile(file) {
			this.item.files = this.files.filter(f => f !== file)
		},
	},
}
</script>

<template>
	<div class="modal-content">
		<form @submit.prevent="handleSubmit">
			<div class="modal-header">
				<h5 class="modal-title my-0">Edit agenda item</h5>
				<button @click.prevent="$emit('close')" type="button" class="btn-close" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-title"
						>Agenda item <small class="text-danger-400">*</small></label
					>
					<input
						type="text"
						class="form-control"
						id="agenda-item-title"
						v-model="title"
						required
						minlength="3"
						placeholder="What should be discussed?"
					/>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-text">Text / Description</label>

					<textarea
						class="form-control"
						id="agenda-item-text"
						v-model="text"
						placeholder="More information about this agenda item (optional)"
					></textarea>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-internal-note">Internal note</label>
					<textarea
						class="form-control"
						id="agenda-item-internal-note"
						v-model="internal_note"
						placeholder="Comment that only logged in clerks see, to share notes about an agenda item (optional)"
					></textarea>
				</div>

				<div class="form-group mb-3">
					<label class="form-label">Speaker / Presenter</label>
					<div v-if="speaker_id">
						<person-link :id="speaker_id" :avatar="18" :preview="true" />
						<button type="button" class="btn btn-sm text-danger-300 ms-2" @click="unlinkSpeaker">
							Unlink speaker
						</button>
					</div>
					<people-search
						v-else
						@person="setSpeaker"
						:limit="5"
						:required="false"
						:placeholder="'Name/email/phone of a person from jurisdiction'"
					></people-search>
				</div>

				<div class="form-row">
					<label class="form-label" for="agenda-item-file">Attachments (documents or other files)</label>

					<div class="row row-cols-3 gap-0">
						<div v-for="(newFile, index) in new_files" :key="`file-${index}`" class="col mb-3">
							<file-preview :file="newFile" @remove="removeNewFile" />
						</div>
						<div v-for="filePath in files" :key="filePath" class="col mb-3">
							<file-path-preview :filePath="filePath" @remove="removeExistingFile" />
						</div>
						<div class="col mb-3">
							<label
								class="d-block bg-light border border-dashed rounded-1 p-3 text-center cursor-pointer"
							>
								<font-awesome-icon :icon="['fas', 'file']" class="text-primary mb-2" />

								<p class="mb-0">
									Upload file
								</p>

								<input
									type="file"
									class="form-control d-none"
									id="agenda-item-file"
									@change="addNewFile"
									multiple
								/>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button :disabled="loading" class="btn btn-primary">Save</button>
			</div>
		</form>
	</div>
</template>

<script>
import PeopleSearch from '@/components/PeopleSearch.vue'
import FilePreview from '@/components/FilePreview.vue'
import FilePathPreview from '@/components/FilePathPreview.vue'
import PersonLink from '@/components/PersonLink'

export default {
	components: { PeopleSearch, FilePreview, FilePathPreview, PersonLink },
	props: {
		item: { type: Object },
	},
	data() {
		return {
			// data to submit
			parent_id: this.item.parent_id,
			title: this.item.title,
			text: this.item.text,
			order: this.item.order,
			internal_note: this.item.internal_note,
			speaker_id: this.item.speaker_id,
			files: this.item.files,
			new_files: [],
			// ui state
			loading: false,
		}
	},
	methods: {
		handleSubmit() {
			// HACK: we don't set `loading` back to false after $emit()
			// because emit is async and won't wait for the parent to finish.
			// It's possible to make this method itself async, but then loading update won't work for some reason.
			// So we just set it to true and that's it. We know parent will close this modal
			// and all the state will be reset.
			this.loading = true
			this.$emit('submit', {
				id: this.item.id,
				parent_id: this.parent_id,
				title: this.title,
				text: this.text,
				internal_note: this.internal_note,
				speaker_id: this.speaker_id,
				files: this.files,
				new_files: this.new_files,
			})
		},
		setSpeaker(speaker) {
			this.speaker_id = speaker.id
		},
		addNewFile($event) {
			this.new_files.push(...$event.target.files)
		},
		removeExistingFile(file) {
			this.files = this.files.filter(f => f !== file)
		},
		removeNewFile(file) {
			this.new_files = this.new_files.filter(f => f !== file)
		},
		unlinkSpeaker() {
			this.speaker_id = null
		},
	},
}
</script>
